import { PureComponent } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class VenturePartners extends PureComponent {
    render() {
        return (
            <>
                <div className="ssTitle01 wow fadeInUp mb-10" data-wow-delay="0.2s">Venture Partners</div>

                <div className="VPMainBX wow fadeInUp" data-wow-delay="0.4s">
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-01.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-02.webp?v1"
                            alt=""
                        />
                        Hassan (Hatu) Sheikh
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-03.webp"
                            alt=""
                        />
                    </div>
                    
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-013.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-04.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-05.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/silent.webp"
                            alt=""
                        />
                        Andrea Castiglione
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-07.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-08.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-09.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-010.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/mexc.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-012.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/bitangels.webp"
                            alt=""
                        />
                        Michael Terpin
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/fairum.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-014.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-015.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-016.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-017.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-018.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-019.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-020.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-021.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-022.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-023.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-024.webp"
                            alt=""
                        />
                    </div>
                    {/* <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-025.webp"
                            alt=""
                        />
                    </div> */}
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-026.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-027.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-029.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-030.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-031V2.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-032.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-033.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-034.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-035.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-036.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-037.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-038.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-039.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-044.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/legion.webp"
                            alt=""
                        />
                    </div>
                    <div className="VPSubBX01">
                        <LazyLoadImage
                            src="images/venture-partners/vpICON-046.webp"
                            alt=""
                        />
                    </div>
                </div>
            </>
        )
    }

}